import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Notfound from "../assets/images/404.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO
      seo={{
        metaTitle: "404: Not found",
        metaDescription: "It looks like you got lost",
      }}
    />
    <div className="bg-white">
        <div className="text-center">
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            NOT FOUND
          </p>
        </div>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <img className="w-1/2 mx-auto" src={Notfound} />
        </div>
    </div>
  </Layout>
);

export default NotFoundPage;
